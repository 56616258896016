import "../styles/globals.css"
import React, { useEffect } from "react"
import parser from "ua-parser-js"
import NextApp from "next/app"
import * as Sentry from "@sentry/node"
import { createMuiTheme, ThemeProvider } from "@material-ui/core"
import mediaQuery from "css-mediaquery"

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const mobileSsrMatchMedia = query => ({
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: "0px",
  }),
})
const desktopSsrMatchMedia = query => ({
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: "1024px",
  }),
})

const theme = {
  palette: {
    primary: {
      main: "#22279D",
    },
    secondary: {
      main: "#A0CD88",
    },
  },
}

const mobileMuiTheme = createMuiTheme({
  ...theme,
  props: {
    // Change the default options of useMediaQuery
    MuiUseMediaQuery: { ssrMatchMedia: mobileSsrMatchMedia },
  },
})

const desktopMuiTheme = createMuiTheme({
  ...theme,
  props: {
    // Change the default options of useMediaQuery
    MuiUseMediaQuery: { ssrMatchMedia: desktopSsrMatchMedia },
  },
})

export const getInitialProps = async ctx => {
  // I'm guessing on this line based on your _document.js example
  const initialProps = await NextApp.getInitialProps(ctx)
  // OP's edit: The ctx that we really want is inside the function parameter "ctx"
  const deviceType =
    parser(ctx.ctx.req.headers["user-agent"]).device.type || "desktop"
  // I'm guessing on the pageProps key here based on a couple examples
  return { pageProps: { ...initialProps, deviceType } }
}

const MyApp = ({ Component, pageProps: { selections, ...pageProps }, err }) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  return (
    <ThemeProvider
      theme={
        pageProps.deviceType === "mobile" ? mobileMuiTheme : desktopMuiTheme
      }
    >
      {/* Adding err is a workaround for https://github.com/vercel/next.js/issues/8592 */}
      <Component {...pageProps} err={err} />
    </ThemeProvider>
  )
}

export default MyApp
